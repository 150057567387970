import * as React from "react"
import { graphql, Link, PageProps } from "gatsby"

import Layout from "../components/layout"
import ThemeContext from "../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

type DataProps = {
  site: {
    buildTime: string
  }
}

const IndexPage = ({ data }: PageProps<DataProps>) => {


  function splatEffect() {
    // splat(0.500184876440619, 0.5004668669644633, -263.2648477845647, -493.41067173776156,{b: 0.10865972150720678, g: 0.10009, r: 1.6});
  };

  var image = data.photos.edges[0];

  return (
    <ThemeContext.Consumer>
        {theme => (
<div>
    <div id="page-wrapper">
      <h1>HORSES</h1>

      <p>
        DISCOVER OUR HORSES
      </p>

    </div>
    <div className="imageFullWidth">
    <GatsbyImage
     fluid={image.node.childImageSharp.fluid}
      alt={image.node.base.split("-").join(" ").split(".")[0]}
     image={getImage(image.node)}
   />
    </div>
    <div
    style={{
      margin: `0`,
      height: 600,
      padding: `30px`,
      background: `#d0cbc2`,
      color:`#111`
    }}
    >

    <h3>DISCOVER OUR HORSES</h3>
    <p>DEMO CONTENT --- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    </div>
    </div>
  )}
    </ThemeContext.Consumer>
  )
}

IndexPage.Layout = Layout

export default IndexPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "horses"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }

  }
`
